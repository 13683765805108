// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-brand-template-js": () => import("./../src/templates/brandTemplate.js" /* webpackChunkName: "component---src-templates-brand-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ott-js": () => import("./../src/pages/ott.js" /* webpackChunkName: "component---src-pages-ott-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-condition-js": () => import("./../src/pages/terms_condition.js" /* webpackChunkName: "component---src-pages-terms-condition-js" */)
}

